import React, { useEffect } from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'
import CertificationsContent from '../components/Certifications/Certifications'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { CERTIFICATIONS as CERTIFICATIONS_LINK } from '../components/internal-links'
import { formatForBigQuery, isMobile } from '../formatForBigQuery'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
} from '../lib/generate-schema'
import defaultFaqs from '../components/FAQs/faqs-list'

const breadcrumbs: Breadcrumb[] = [
  {
    title: 'Homepage',
    url: '/',
  },
  {
    title: 'Certifications',
    url: CERTIFICATIONS_LINK,
  },
]

const Certifications: React.FC = () => {
  useEffect(() => {
    try {
      window?.rudderanalytics?.track?.('CertificationsPageViewed', {
        event_params: formatForBigQuery({
          device: isMobile() ? 'Mobile' : 'Desktop',
        }),
      })
    } catch (er) {
      console.error(er)
    }
  }, [])

  return (
    <Layout showBreadcrumbs breadcrumbs={breadcrumbs}>
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Certifications: Read About RNG & AIGF Membership Certificate | MegaRummy"
        description="Read about the certifications of MegaRummy. MegaRummy hold a RNG certificates and AIGF member. Click to know why MegaRummy is the safest and most fair platform to play!"
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        faqSchema={generateFaqSchema(defaultFaqs)}
      />
      <CertificationsContent />
    </Layout>
  )
}

export default Certifications
